<template>
  <div>
    <!-- Início Logs -->
		<LogsCron v-if="logmodal" v-on:principal="fecharModal" :statuscron="itemslog" />
    <!-- Fim Logs -->

    <!-- Inicio Loader reprocessar -->
    <v-dialog v-model="loadingReprocessar" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text style="padding-top: 7px">
          Aguarde, Executando Tarefa...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Fim Loader reprocessar -->

    <!-- Início principal -->
    <v-card v-if="!logmodal">
      <v-toolbar flat>
        <v-icon> fas fa-hard-hat </v-icon>
        <v-toolbar-title class="pl-4"> Status Crons </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="fechar">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="mt-4">
          <v-col cols="3"> </v-col>
          <v-col cols="6">
            <v-data-table
              dense
              :headers="grid.status.cabecalho"
              :items="grid.status.items"
              :items-per-page="grid.status.porPagina"
              hide-default-footer
              class="elevation-1 pt-4"
              :loading="grid.status.carregando"
            >
              <!-- This template looks for headers with formatters and executes them -->
              <template
                v-for="header in grid.status.cabecalho.filter((header) =>
                  header.hasOwnProperty('formatter')
                )"
                v-slot:[`item.${header.value}`]="{ header, value }"
              >
                {{ header.formatter(value) }}
              </template>
              <!-- Inicio Detalhamento -->
              <template #item.descricao="{ item }">
                <a @click="carregarLogs(item)" style="text-decoration: none" >
                  {{ item.descricao }}
                </a>
              </template>
              <!-- Fim Detalhamento -->

              <!-- Inicio color Status -->
              <template #item.status="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-app-bar-nav-icon @click="carregarStartJob( item )" v-on="on" >
                      <div style="display: flex; align-items: center; justify-content: center;" >
                        <td>
                          <v-icon size="16" :color="getColor(item.status)" >fa-solid fa-circle</v-icon >
                        </td>
                      </div>
                    </v-app-bar-nav-icon>
                  </template>
                  <span>Executar Cron</span>
                </v-tooltip>
              </template>
              <!-- Final color Status -->

            </v-data-table>
          </v-col>

          <!-- <v-col cols="1"> </v-col> -->

          <v-col cols="3">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="3"> </v-col>
                  <v-col cols="6" style="padding-bottom: 0px; padding-top: 0px">
                    <b>Legenda</b>
                  </v-col>
                  <v-col cols="3"> </v-col>
                </v-row>

                <v-row>
                  <v-col cols="10" style="padding-bottom: 0px; padding-top: 0px">
                    Operacional
                  </v-col>
                  <v-col cols="2" style="padding-bottom: 0px; padding-top: 0px">
                    <v-icon size="16" :color="getColor(0)" >fa-solid fa-circle</v-icon >
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="10" style="padding-bottom: 0px; padding-top: 0px">
                    Processando
                  </v-col>
                  <v-col cols="2" style="padding-bottom: 0px; padding-top: 0px">
                    <v-icon size="16" :color="getColor(1)" >fa-solid fa-circle</v-icon >
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="10" style="padding-bottom: 0px; padding-top: 0px">
                    Falha
                  </v-col>
                  <v-col cols="2" style="padding-bottom: 0px; padding-top: 0px">
                    <v-icon size="16" :color="getColor(2)" >fa-solid fa-circle</v-icon >
                  </v-col>
                </v-row>

                <v-row>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row>
                  <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px">
                    <v-spacer></v-spacer>
                  </v-col>
                  <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px">
                    <v-spacer></v-spacer>
                  </v-col>
                  <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px">
                      <v-divider></v-divider>
                  </v-col>

                  <v-col cols="3"></v-col>
                  <v-col cols="6" style="padding-bottom: 0px; padding-top: 0px">
                    <b>Importações</b>
                  </v-col>
                  <v-col cols="3"></v-col>

                  <v-col cols="1"></v-col>
                  <v-col cols="10">
                    <b>Última Importação: {{ this.dataImport.dataImport }}</b>
                  </v-col>
                  <v-col cols="1"></v-col>

                  <v-col cols="12">
                    <v-data-table
                      dense
                      :headers="dataImport.cabecalho"
                      :items="dataImport.items"
                      hide-default-footer
                      class="elevation-1 pt-4"
                    >
                    <template #item.total="{ item }">
                      <span class="alinhamentoRow">
                        {{ item.total }}
                      </span>
                    </template>

                      <!-- This template looks for headers with formatters and executes them -->
                      <template
                        v-for="header in dataImport.cabecalho.filter((header) =>
                          header.hasOwnProperty('formatter')
                        )"
                        v-slot:[`item.${header.value}`]="{ header, value }"
                      >
                        {{ header.formatter(value) }}
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>

            </v-card>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
    <!-- Fim principal -->
  </div>
</template>

<script>
import { baseApiUrl } from "@/global";
import axios from "axios";
import moment from "moment";
import Template from "../components/Template.vue";
import LogsCron from "../components/logs/frmLogCron.vue";

export default {
  name: "StatusCron",
  components: {
    Template,
    LogsCron,
  },
  data: () => ({
    grid: {
      status: {
        cabecalho: [
          { text: "Descrição", value: "descricao", sortable: false },
          { text: "Ultimo Movimento", value: "alterado_em", sortable: false, formatter: (x) => x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null, },
          { text: "Status", value: "status", sortable: false },
        ],
        items: [],
        carregando: false,
        paginaAtual: 1,
        totalRegistros: 0,
        porPagina: 100,
        qtdsPaginacao: [10, 15, 50, 100],
        itemSelecionado: [],
        coluna: "codigo",
        direcao: "desc",
      },
    },
    filtros: {
      codigo: "",
    },
    itemslog: {
      idstatus_cron: "",
      descricao: "",
      alterado_em : "",
      status: "",
    },
    logmodal: false,
    loadingReprocessar: false,
    principal: true,
    itensFiltro: [],
    dataImport: {
        cabecalho: [
          { text: "Transportadora", value: "nome", sortable: false },
          { text: "Quantidade", value: "total", sortable: false },
        ],
        items: [],
        dataImport: '',
    },
    timer: '',
  }),
  created () {
    // this.timer = setInterval( this.carregarStatus(), 60 * 10000 );
  },
  mounted () {
    this.carregarStatus();
  },
  beforeDestroy () {
    this.stopRelease();
  },
  methods: {
    limparFiltros() {
      this.filtros.codigo = "";
    },
    fechar() {
      this.$router.push("/");
    },
    fecharModal() {
      this.logmodal = false;
      this.carregarStatus();
    },
    stopRelease() {
      clearInterval(this.timer);
    },
    getColor(color) {
      if (color == 0) {
        return "#3CA40B"; // verde
      } else if (color == 1) {
        return "#FAB913"; // laranja
      } else if (color > 1) {
        return "#FF0000"; // vermelho
      }
    },
    carregarLogs(item) {
      this.itemslog = item;
      this.logmodal = true;
      this.principal = false
    },
    async carregarStatus() {
      this.grid.status.carregando = true;
      let url = `${baseApiUrl}/status-crons`;

      await axios
        .get(url)
        .then((res) => {
          if (res.data.retorno) {
            this.grid.status.items = res.data.dados.data;

            const stringJson = JSON.parse(res.data.dados.data[0].obs);
            this.dataImport.dataImport = stringJson.dataImport != null ? moment(stringJson.dataImport).format("DD/MM/yyyy") : ""
            this.dataImport.items = stringJson.recImports
// console.log( "this.dataImport.items", this.dataImport.items);
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => (this.grid.status.carregando = false));
    },
    async carregarStartJob( recno ) {
      this.$swal({
        title: 'EXECUTAR CRON',
        text: `Rodar Cron ${recno.descricao} ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.loadingReprocessar = true;

          let url = `${baseApiUrl}/refresh-job?jobControl=${recno.idstatus_cron}`;
          axios
            .get(url)
            .then((res) => {
              if (res.data.retorno) {
                this.loadingReprocessar = false;
                this.$swal({
                  title: 'Sucesso!!!',
                  text: 'Comando executado',
                  icon: 'success',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#4BB543',
                  allowOutsideClick: true,
                });
              } else {
                console.log(res.data.dados);
                this.$swal({
                  title: 'Que Pena!!!',
                  text: res.data.dados,
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#4BB543',
                  allowOutsideClick: true,
                });
              }
            })
            .catch((error) => {
              this.$swal({
                title: 'Falha!!!',
                text: error,
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#4BB543',
                allowOutsideClick: true,
              });
            })
            .finally(() => {
              this.carregarStatus();
              this.loadingReprocessar = false;
            });
        }
      });
    },
  },
};
</script>

<style>


.alinhamentoRow{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-decoration: none;
  cursor: pointer;
}

</style>